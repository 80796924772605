import React from 'react';
import { useSelector } from 'react-redux';
import * as S from './Nav.style';
import { menuSections, idToIndex } from '../../View/siteStructure';
import { scrollToId } from '../../utils/scrollToId';

function Nav() {
  const { activeSection } = useSelector((state) => state.app);

  const handleClick = (e) => {
    const nr = e.target.value;
    scrollToId(nr);
  };

  const menuItems = menuSections.map(({ name, id }, i) => (
    <S.Element key={name}>
      <S.MenuButton
        active={i === idToIndex[activeSection]}
        aria-label={name}
        value={id}
        onClick={handleClick}>
        {name}
      </S.MenuButton>
    </S.Element>
  ));

  return (
    <S.Wrapper>
      <S.List addBackground={idToIndex[activeSection] !== 0}>{menuItems}</S.List>
    </S.Wrapper>
  );
}

export default Nav;
