import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { bp } from '../layout/theme';
import { getWidthEm } from '../utils/getWidthEm';
import { unlockScroll } from '../utils/scroll';
import { updateIsMobile, toggleMenu, toggleOffer, toggleCallToAction } from '../store/app/actions';

function useMobile() {
  const dispatch = useDispatch();
  const { isMobile, isMenuOpen, isCallOpen, isOfferOpen } = useSelector((state) => state.app);

  const checkIsMobile = () => {
    const widthEm = getWidthEm();
    const check = bp.md > widthEm;
    if (isMobile === check) return;
    dispatch(updateIsMobile(check));
  };

  useLayoutEffect(() => {
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    return () => {
      window.removeEventListener('resize', checkIsMobile);
      unlockScroll();
    };
  }, []);

  const switchToDesktop = () => {
    if (isMenuOpen) dispatch(toggleMenu());
    if (isOfferOpen) dispatch(toggleOffer());
    if (isCallOpen) dispatch(toggleCallToAction());
    if (isMenuOpen || isOfferOpen || isCallOpen) unlockScroll();
  };

  useLayoutEffect(() => {
    if (isMobile === false) switchToDesktop();
  }, [isMobile, isMenuOpen, isCallOpen, isOfferOpen]);
}

export default useMobile;
