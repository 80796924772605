import React from 'react';
import Layout from './layout/layout';
import Site from './View/Site';

import useMobile from './hooks/useMobile';
import useGA from './hooks/useGA';

function App() {
  useMobile();
  useGA();

  return (
    <Layout>
      <Site />
    </Layout>
  );
}

export default App;
