export const offerIdToPath = [
  'uslugi_kadrowe',
  'obsluga_placowo-kadrowa',
  'uslugi_rozliczen',
  'cennik',
];

export const offer = [
  {
    title: 'Usługi kadrowe',
    content: [
      'prowadzenie ksiąg rachunkowych na podstawie dokumentów dostarczonych przez Klienta lub w jego siedzibie',
      'prowadzenie księgi przychodów i rozchodów oraz zryczałtowanych form opodatkowania',
      'przygotowanie i weryfikacja we współpracy z Klientem polityki rachunkowości, w tym zakładowego planu kont',
      'przygotowanie okresowych raportów finansowych zgodnie ze standardami Klienta',
      'sporządzanie rocznych sprawozdań finansowych zgodnie z wymogami Ustawy o rachunkowości',
      'prowadzenie ewidencji środków trwałych i wyposażenia',
    ],
  },
  {
    title: 'Obsługa płacowo-kadrowa',
    content: [
      'tworzenie i administracja teczek personalnych',
      'monitorowanie ważności badań lekarskich i szkoleń BHP',
      `sporządzanie:
      deklaracji PIT i ZUS
      umów o pracę
      umów cywilno-prawnych,
      świadectw pracy
      zaświadczeń o zarobkach i zatrudnieniu`,
      `przygotowywanie:
      miesięcznych deklaracji ubezpieczeniowych
      dokumentacji płacowej dla firmy i jej pracowników`,
      'rejestracja przedsiębiorcy i pracowników dla celów ubezpieczenia społecznego oraz zdrowotnego',
      'prowadzenie ewidencji wynagrodzeń,',
      'asysta przy płatnościach wynagrodzeń i składek na ubezpieczenia społeczne,',
      'naliczanie wynagrodzeń z uwzględnieniem dodatków i potrąceń',
      'naliczanie świadczeń związanych z chorobą i macierzyństwem',
      'sporządzanie kalkulacji miesięcznych zaliczek na podatek dochodowy od osób fizycznych',
      'przygotowywanie rocznych informacji o dochodach oraz zaliczkach na podatek dochodowy od osób fizycznych',
      'terminowe dostarczanie powyższych deklaracji do stosownych organów',
    ],
  },
  {
    title: 'Usługi rozliczeń podatkowych',
    content: [
      'pomoc przy wyborze najkorzystniejszej formy opodatkowania',
      'prowadzenie obsługi kontroli przeprowadzanych przez US, ZUS, PIP i inne instytucje',
      'rejestracja podatników w organach podatkowych, dokonywanie zgłoszeń identyfikacyjnych oraz zgłoszeń aktualizujących',
      'prowadzenie rejestrów VAT sprzedaży i rejestrów VAT zakupu oraz sporządzanie deklaracji VAT',
      'sporządzanie kalkulacji miesięcznej zaliczki na podatek dochodowy od osób prawnych oraz sporządzanie rocznych deklaracji CIT',
      'sporządzanie kalkulacji zaliczek na podatek dochodowy od osób fizycznych prowadzących działalność gospodarczą oraz sporządzanie rocznych deklaracji PIT',
      'raporty dla organów statystycznych administracji państwowej, Narodowego Banku Polskiego oraz przygotowywanie innych rozliczeń i deklaracji dla organów podatkowych, jeśli są wymagane',
      'terminowe dostarczanie powyższych deklaracji do stosownych organów',
    ],
  },
  {
    title: 'Cennik',
  },
];

export const price = [
  {
    title: 'Księga Przychodów i Rozchodów\n lub Ryczałt Ewidencjonowany',
    content: [
      'Ilość\nzaksięgowanych\npozycji',
      'ewidencja VAT',
      'nie',
      'tak',
      'do 10',
      '150',
      '200',
      '11 - 20',
      '200',
      '250',
      '21 - 30',
      '250',
      '300',
      'każde kolejne\n15 pozycji',
      '50',
      '50',
    ],
  },
  {
    title: 'Księgi rachunkowe',
    content: [
      'Ilość\nzaksięgowanych\npozycji',
      'kwota\nnetto',
      'do 30',
      '600',
      '31 - 60',
      '800',
      'każde kolejne\n30 pozycji',
      '300',
      'powyżej 200\ndokumentów',
      'ustalane\nindywidualnie',
    ],
  },
  {
    title: 'Inne usługi',
    content: [
      'Obsługa płacowa\npracownika',
      '50',
      'Obsługa\npłacowo-kadrowa\npracownika',
      '60',
      'Obsługa umów\ncywilnoprawnych',
      '50',
      'Założenie\nakt osobowych\npracownika',
      '60',
      'Sporządzenie\ninformacji\nrocznej PIT-11',
      '20',
      'Sporządzenie polityki\nrachunkowości',
      '1000',
      'Sporządzenie\nsprawozdania\nfinansowego',
      '1000',
      `Rejestracja sp. z o.o.\nprzez internet\n„w trybie S24”`,
      '600',
    ],
  },
];

export const pricingInfo = `* Wszystkie podane ceny są cenami netto (do podanych kwot należy doliczyć 23% VAT).\nPowyższy cennik służy do wyliczenia szacunkowych kosztów współpracy i nie stanowi oferty w rozumieniu art. 66 Kodeksu cywilnego.\nWyżej wymienione ceny podlegają negocjacji.`;
