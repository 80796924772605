import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as S from './Welcome.style';
import image from '../../assets/images/start';
import ButtonText from '../../components/ButtonText/ButtonText';
import { scrollToId } from '../../utils/scrollToId';
import { menuSections, idToIndex } from '../../View/siteStructure';
import useEventGA from '../../hooks/useEventGA';

import { ReactComponent as Logo } from '../../assets/logo/logoLongBRP.svg';

const sectionIndex = idToIndex.start;
const eventProps = { category: 'Nawigacja', action: 'Poznaj ofertę', label: 'widok startowy' };

function Welcome({ updateRef }) {
  const [handleEvent] = useEventGA();
  const myRef = useRef();
  const handleClick = () => {
    scrollToId(menuSections[1].id);
    handleEvent(eventProps);
  };

  useEffect(() => {
    updateRef(myRef);
  }, []);

  return (
    <S.Wrapper id={menuSections[sectionIndex].id} ref={myRef}>
      <Logo id="logoBrp" />
      <S.Container>
        <S.Background image={image} alt="dbamy-o-Twoją-księgowość-biuro-Jaworzno" />
        <S.BgTransition />
      </S.Container>
      <S.Section>
        <S.Header>
          Dbamy <span>o Twoją</span>
          <br />
          księgowość
        </S.Header>
        <ButtonText onClick={handleClick}>Poznaj ofertę</ButtonText>
      </S.Section>
    </S.Wrapper>
  );
}

Welcome.propTypes = {
  updateRef: PropTypes.func.isRequired,
};

export default Welcome;
