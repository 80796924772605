import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { updateActiveSection } from '../store/app/actions';
import { menuSections, idToIndex } from '../View/siteStructure';

const buildThresholdArray = () => Array.from(Array(100).keys(), (i) => i / 100);

const config = {
  root: null,
  rootMargin: '0px',
  threshold: buildThresholdArray(),
};

function useObserver() {
  const dispatch = useDispatch();
  const ratioRef = useRef([0, 0, 0, 0, 0]);
  const lastIdRef = useRef(null);

  const observerRef = useRef(null);

  const findBiggest = (arr) => {
    const maxIndex = arr.indexOf(Math.max.apply(null, arr));
    const maxValue = arr[maxIndex];
    return [maxIndex, maxValue];
  };

  const updateRatioRef = (changes) =>
    changes.forEach(({ intersectionRatio, target: { id } }) => {
      ratioRef.current[idToIndex[id]] = Math.floor(intersectionRatio * 100);
    });

  const onChange = (changes) => {
    updateRatioRef(changes);
    const [maxIndex] = findBiggest(ratioRef.current);

    const { id } = menuSections[maxIndex];
    if (lastIdRef.current !== id) {
      lastIdRef.current = id;
      dispatch(updateActiveSection(id));
    }
  };

  const updateObserver = () => {
    if (observerRef.current) observerRef.current.disconnect();
    observerRef.current = new IntersectionObserver(onChange, config);
  };

  useEffect(() => {
    if ('IntersectionObserver' in window) updateObserver();
    return () => {
      if ('IntersectionObserver' in window && observerRef.current) observerRef.current.disconnect();
    };
  }, []);

  const updateRef = (ref) => {
    if (observerRef.current) observerRef.current.observe(ref.current);
  };

  return [updateRef];
}

export default useObserver;
