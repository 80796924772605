import {
  TOGGLE_MENU,
  TOGGLE_OFFER,
  TOGGLE_CALL_TO_ACTION,
  UPDATE_MOBILE,
  UPDATE_ACTIVE_SECTION,
  UPDATE_OFFER_SECTION,
  UDPATE_COOKIES_STATE,
} from './types';

export const toggleMenu = () => ({
  type: TOGGLE_MENU,
});

export const toggleOffer = () => ({
  type: TOGGLE_OFFER,
});

export const toggleCallToAction = () => ({
  type: TOGGLE_CALL_TO_ACTION,
});

export const updateIsMobile = (isMobile) => ({
  type: UPDATE_MOBILE,
  payload: isMobile,
});

export const updateActiveSection = (id) => ({
  type: UPDATE_ACTIVE_SECTION,
  payload: id,
});

export const updateOfferSection = (id) => ({
  type: UPDATE_OFFER_SECTION,
  payload: id,
});

export const updateCookiesState = (isAllowed) => ({
  type: UDPATE_COOKIES_STATE,
  payload: isAllowed,
});
