import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { offerIdToPath } from '../sections/Offer/Offer.text';
import { menuSections } from '../View/siteStructure';
import { disableGA } from '../utils/disableGA';

const delayTime = 750;
const offerId = menuSections[2].id;

function useGA() {
  const { isCookiesAllowed, activeSection, offerSection, isOfferOpen, isMobile } = useSelector(
    (state) => state.app,
  );
  const timerId = useRef();
  const lastPath = useRef();

  const setTimerId = (id) => {
    clearTimeout(timerId.current);
    timerId.current = id;
  };

  const updatePath = (path) => {
    const timerIdTask = setTimeout(() => {
      setTimerId(null);
      if (path === lastPath.current) return;
      lastPath.current = path;
      ReactGA.pageview(path);
    }, delayTime);

    setTimerId(timerIdTask);
  };

  const createPath = () => {
    let path = `/${activeSection}`;
    if (activeSection === offerId && (isOfferOpen || isMobile === false))
      path += `/${offerIdToPath[offerSection]}`;
    return path;
  };

  useEffect(() => {
    disableGA(!isCookiesAllowed);
    if (!isCookiesAllowed) return;

    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    ReactGA.set({ anonymizeIp: true });
  }, [isCookiesAllowed]);

  useEffect(() => {
    if (isCookiesAllowed) {
      const path = createPath();
      updatePath(path);
    }

    return () => clearTimeout(timerId.current);
  }, [isCookiesAllowed, activeSection, offerSection, isOfferOpen, isMobile]);
}

export default useGA;
