import styled from 'styled-components';
import { visibleIn, visibleOut } from '../../animations/visible';

export const Wrapper = styled.nav`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto 1fr;

  background: ${({ theme }) => theme.colors.bg};

  ${({ isOpen }) => (isOpen ? visibleIn : visibleOut)};

  ${({ theme }) => theme.mq.md} {
    display: none;
  }

  :before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 100%;
    width: 56vh;
    height: 56vh;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.decoration900};
    transform: translate(-15%, -50%);
  }
`;

export const Element = styled.li`
  list-style: none;
  text-align: center;
  margin-top: 2.5vh;
`;

export const List = styled.ul`
  grid-row-start: 2;

  ${Element}:first-of-type {
    margin-top: 0;
  }
`;

export const MenuButton = styled.button`
  font-size: ${({ theme }) => theme.fontSize[3].sm};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  padding: 1.2vh 0;
`;

export const CloseButton = styled.button`
  grid-row-start: 3;
  justify-self: center;
  align-self: center;

  svg {
    width: 5.5rem;
    filter: drop-shadow(0px 0px 5px ${({ theme }) => theme.colors.decoration900});
  }
`;
