export const colors = {
  primary: '#fff',
  bg: '#000',
  bgImage: '#0A100E',
  bgTransition: '#0B120F',
  bgNav: 'rgba(0, 0, 0, 0.7)',
  decoration900: '#E6007E',
  decoration700: '#FB44A8',
  highlight: 'rgba(255, 255, 255, 0.2)',
};
