import styled, { css } from 'styled-components';

export const Wrapper = styled.nav`
  z-index: 100;
  display: none;

  ${({ theme }) => theme.mq.md} {
    display: inline;
  }
`;

export const List = styled.ul`
  display: flex;
  border-bottom-left-radius: 3rem;

  ${({ addBackground, theme }) =>
    addBackground &&
    css`
      background: ${theme.colors.bgNav};
    `}

  transition: background-color 0.5s ease-in-out;
`;

export const Element = styled.li`
  list-style: none;
  text-align: center;
`;

export const MenuButton = styled.button`
  font-size: ${({ theme }) => theme.fontSize[0].lg};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  padding: 0.75rem 0;
  margin: 0.75rem 2rem;
  color: inherit;
  border-color: rgba(0, 0, 0, 0);
  transition: border-color 0.5s ease-in-out;

  ${({ active }) =>
    active &&
    css`
      border-bottom: 0.3rem solid ${({ theme }) => theme.colors.decoration900};
    `}
`;
