import styled, { css } from 'styled-components';

const textStyle = css`
  font-size: ${({ theme }) => theme.fontSize[3].sm};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-family: ${({ theme }) => theme.fontFamily.decoration};
  line-height: 150%;
  letter-spacing: 0.04em;

  ${({ theme }) => theme.mq.lg} {
    font-size: ${({ theme }) => theme.fontSize[3].md}; // 48
  }
`;

export const header = styled.h1`
  ${textStyle}
  span {
    ${textStyle}
  }
`;
