import React, { useState, useRef } from 'react';

import PropTypes from 'prop-types';

import * as S from './Background.style';

function Background({ image, alt, className }) {
  const { src, sizes, altFormat } = image;

  const [imageUrl, setImageUrl] = useState(null);
  const componentRef = useRef();

  const copyUrl = () => {
    const url = componentRef.current.currentSrc;
    setImageUrl(url);
  };

  const path = (size, format) => `${src}_${size}w.${format} ${size}w`;
  const srcSet = (format) => sizes.map((size) => path(size, format)).join(',');

  return (
    <S.Container url={imageUrl} className={className}>
      <S.Image>
        <source type="image/webp" srcSet={srcSet('webp')} />
        <source type={`image/${altFormat}`} srcSet={srcSet(altFormat)} />
        <img onLoad={copyUrl} ref={componentRef} src={`${src}.${altFormat}`} alt={alt} />
      </S.Image>
    </S.Container>
  );
}

Background.propTypes = {
  className: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string,
    sizes: PropTypes.arrayOf(PropTypes.number),
    altFormat: PropTypes.string,
  }).isRequired,
  alt: PropTypes.string.isRequired,
};

export default Background;
