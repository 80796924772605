import React from 'react';

import PropTypes from 'prop-types';

import * as S from './ButtonText.style';

function ButtonText({ children, onClick }) {
  return <S.Wrapper onClick={onClick}>{children}</S.Wrapper>;
}

ButtonText.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ButtonText;
