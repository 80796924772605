import styled from 'styled-components';
import Img from '../../components/Background/Background';
import { header } from '../../ui/header';

export const Wrapper = styled.div`
  z-index: 1;
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;

  #logoBrp {
    position: absolute;
    top: max(2vh, 1rem);
    left: max(2vh, 1rem);
    height: 4rem;
    z-index: 300;
  }

  ${({ theme }) => theme.mq.sm} {
    margin-bottom: 5rem;
  }

  ${({ theme }) => theme.mq.md} {
    #logoBrp {
      height: 5rem;
    }
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.bgImage};

  ${({ theme }) => theme.mq.portrait} {
    height: 50vh;
  }
`;

export const Background = styled(Img)`
  mix-blend-mode: color-dodge;
`;

export const BgTransition = styled.div`
  width: 100%;
  height: 15vh;
  background: linear-gradient(
    ${({ theme }) => theme.colors.bgTransition},
    ${({ theme }) => theme.colors.bg}
  );
`;

export const Section = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-top: calc(40vh);

  button {
    margin: auto;
  }

  ${({ theme }) => theme.mq.portrait} {
    margin-top: calc(50vh - 6rem);
    padding-bottom: 7rem;
  }
`;

export const Header = styled(header)`
  margin: 0;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize[4].sm};

  span {
    font-size: inherit;
  }

  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => theme.fontSize[4].md};

    span {
      font-size: inherit;
    }
  }

  ${({ theme }) => theme.mq.portrait} {
    text-align: left;
    span {
      display: block;
    }
    br {
      display: none;
    }
  }
`;
