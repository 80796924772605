import React, { Suspense } from 'react';
import * as S from './Site.style';
import Header from '../components/Header/Header';
import ButtonCall from '../components/ButtonCall/ButtonCall';
import CookiesInfo from '../components/CookiesInfo/CookiesInfo';
import Welcome from '../sections/Welcome/Welcome';
import useObserver from '../hooks/useObserver';

const AboutUs = React.lazy(() => import('../sections/AboutUs/AboutUs'));
const Offer = React.lazy(() => import('../sections/Offer/Offer'));
const Faq = React.lazy(() => import('../sections/Faq/Faq'));
const Contact = React.lazy(() => import('../sections/Contact/Contact'));
const Footer = React.lazy(() => import('../sections/Footer/Footer'));

function Fallback() {
  return <S.Loader />;
}

function Site() {
  const [updadeRef] = useObserver();

  return (
    <S.Wrapper>
      <Header />
      <ButtonCall />
      <CookiesInfo />
      <Welcome updateRef={updadeRef} />
      <Suspense fallback={<Fallback />}>
        <AboutUs updateRef={updadeRef} />
      </Suspense>
      <Suspense fallback={<Fallback />}>
        <Offer updateRef={updadeRef} />
      </Suspense>
      <Suspense fallback={<Fallback />}>
        <Faq updateRef={updadeRef} />
      </Suspense>
      <Suspense fallback={<Fallback />}>
        <Contact updateRef={updadeRef} />
      </Suspense>
      <Suspense fallback={<Fallback />}>
        <Footer />
      </Suspense>
    </S.Wrapper>
  );
}

export default Site;
