import styled, { keyframes, css } from 'styled-components';
import { visibleIn, visibleOut } from '../../animations/visible';

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  80% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}`;

const pulseEffect = css`
  animation: ${pulse} 7.5s ease-out infinite;
  animation-fill-mode: both;
`;

export const Wrapper = styled.div`
  z-index: 301;
  position: fixed;
  right: max(2rem, 5vw);
  bottom: max(2rem, 5vw);
  width: 5.1rem;
  svg {
    width: 100%;
    filter: drop-shadow(0px 0px 5px ${({ theme }) => theme.colors.decoration900});
  }

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border: 2.5rem solid ${({ theme }) => theme.colors.decoration900};
    border-radius: 50%;
    ${({ animate }) => animate && pulseEffect}
  }
  ${({ hide }) => (hide ? visibleOut : visibleIn)}

  ${({ theme }) => theme.mq.md} {
    display: none;
  }
`;
