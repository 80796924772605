import styled from 'styled-components';

export const Wrapper = styled.button`
  width: 25rem;
  height: 4.6rem;
  border-radius: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.decoration900};
  font-size: ${({ theme }) => theme.fontSize[2].sm};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-family: ${({ theme }) => theme.fontFamily.decoration};
  box-shadow: 0px 1px 10px ${({ theme }) => theme.colors.decoration900};

  ${({ theme }) => theme.mq.lg} {
    width: 31.25rem;
    height: 5.75rem;
    font-size: ${({ theme }) => theme.fontSize[2].md}; //24
  }
`;
