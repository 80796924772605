export const menuSections = [
  { name: 'Start', id: 'start' },
  { name: 'O nas', id: 'oNas' },
  { name: 'Oferta', id: 'oferta' },
  { name: 'Faq', id: 'faq' },
  { name: 'Kontakt', id: 'kontakt' },
];

export const idToIndex = {
  start: 0,
  oNas: 1,
  oferta: 2,
  faq: 3,
  kontakt: 4,
};

// export const sectionIndex = new Map(menuSections.map((obj, i) => [obj.id, i]));
// export const getSectionIndex = (id) => sectionIndex.get(id);
