import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './MobileMenu.style';
import { toggleMenu } from '../../store/app/actions';
import { unlockScroll } from '../../utils/scroll';
import { scrollToId } from '../../utils/scrollToId';
import { menuSections } from '../../View/siteStructure';

import { ReactComponent as Icon } from '../../assets/icons/close_icon.svg';

function MobileMenu() {
  const dispatch = useDispatch();
  const { isMenuOpen } = useSelector((state) => state.app);

  const closeMenu = () => {
    if (!isMenuOpen) return;
    dispatch(toggleMenu());
    unlockScroll();
  };

  const handleClick = (e) => {
    closeMenu();
    scrollToId(e.target.value, false);
  };

  const menuItems = menuSections.map(({ name, id }) => (
    <S.Element key={name}>
      <S.MenuButton aria-label={name} value={id} onClick={handleClick}>
        {name}
      </S.MenuButton>
    </S.Element>
  ));

  return (
    <S.Wrapper isOpen={isMenuOpen}>
      <S.List>{menuItems}</S.List>
      <S.CloseButton onClick={closeMenu}>
        <Icon />
      </S.CloseButton>
    </S.Wrapper>
  );
}

export default MobileMenu;
