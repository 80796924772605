import React from 'react';
import * as S from './Header.style';

import Hamburger from '../Hamburger/Hamburger';
import Nav from '../Nav/Nav';
import MobileMenu from '../MobileMenu/MobileMenu';

function Header() {
  return (
    <S.Header>
      <Hamburger />
      <Nav />
      <MobileMenu />
    </S.Header>
  );
}

export default Header;
