import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCallToAction } from '../../store/app/actions';
import { menuSections } from '../../View/siteStructure';
import { lockScroll, unlockScroll } from '../../utils/scroll';
import useEventGA from '../../hooks/useEventGA';

import { ReactComponent as CloseIcon } from '../../assets/icons/close_icon.svg';
import { ReactComponent as CallIcon } from '../../assets/icons/call_icon.svg';

import * as S from './ButtonCall.style';

const eventProps = { category: 'Nawigacja', action: 'Otwarto szybki kontakt', label: 'mobile' };

function ButtonCall() {
  const [handleEvent] = useEventGA();

  const dispatch = useDispatch();
  const { isCallOpen, isOfferOpen, isMenuOpen, activeSection } = useSelector((state) => state.app);

  const goTop = () => {
    const myDiv = document.getElementById(menuSections[4].id);
    myDiv.scrollTop = 0;
  };

  const handleClick = () => {
    if (isCallOpen) goTop();
    else handleEvent(eventProps);

    dispatch(toggleCallToAction());
    if (isOfferOpen) return;

    if (isCallOpen) unlockScroll();
    else lockScroll();
  };

  return (
    <S.Wrapper
      animate={!isCallOpen}
      hide={isMenuOpen || (!isCallOpen && activeSection === menuSections[4].id)}
      onClick={handleClick}>
      {isCallOpen ? <CloseIcon /> : <CallIcon />}
    </S.Wrapper>
  );
}

export default ButtonCall;
