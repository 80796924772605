import {
  TOGGLE_MENU,
  TOGGLE_OFFER,
  TOGGLE_CALL_TO_ACTION,
  UPDATE_MOBILE,
  UPDATE_ACTIVE_SECTION,
  UPDATE_OFFER_SECTION,
  UDPATE_COOKIES_STATE,
} from './types';

const initialState = {
  isMenuOpen: false,
  isOfferOpen: false,
  isCallOpen: false,
  isMobile: null,
  activeSection: 0,
  offerSection: 0,
  isCookiesAllowed: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_MENU:
      return { ...state, isMenuOpen: !state.isMenuOpen };

    case TOGGLE_OFFER:
      return { ...state, isOfferOpen: !state.isOfferOpen };

    case TOGGLE_CALL_TO_ACTION:
      return { ...state, isCallOpen: !state.isCallOpen };

    case UPDATE_MOBILE:
      return { ...state, isMobile: payload };

    case UPDATE_ACTIVE_SECTION:
      return { ...state, activeSection: payload };

    case UPDATE_OFFER_SECTION:
      return { ...state, offerSection: payload };

    case UDPATE_COOKIES_STATE:
      return { ...state, isCookiesAllowed: payload };

    default:
      return state;
  }
};
